import go from 'gojs'
import { ReactDiagram } from 'gojs-react'
import { useEffect, useState } from 'react'

import { useDiagramContext } from '@/components/Diagram/context/DiagramContext'
import { toggleGrid } from '@/components/Diagram/handlers'
import {
	addNewTable,
	removeNode,
} from '@/components/Diagram/handlers/nodeHandlers'
import { useDiagramChangeHandler } from '@/components/Diagram/hooks/useDiagramChangeHandler'
import { getNodeDetail } from '@/endpoints'
import { useApi } from '@/endpoints/hooks'
import { StructureDto } from '@/endpoints/models'
import { LinkRoutingType } from '@/enums'
import { useAppContext, useAppStore } from '@/hooks'
import { RootState } from '@/store'
import colors from '@/styles/diagramColors'

import { DiagramPropertiesPanel } from '../DiagramPropertiesPanel'
import {
	useParseNodesAndLinks,
	useReactDiagramDrop,
	useReactDiagramValidate,
} from '../hooks'
import { Container, DiagramContainer } from '../styles'
import {
	setupLinkTemplates,
	setupNodeTemplates,
	useContextMenuTemplate,
} from '../Templates'
import { ValidationError } from '../types'
import { deleteNode, markChanges, useHandleData } from '../utils'
import { DiagramControls } from './DiagramControls'
import { DiagramModals } from './DiagramModals'

export const DiagramWrapper = () => {
	const { diagram, node, selectedTabDetailId, isEditMode } = useDiagramContext()
	const [idNode, setIdNode] = useState<number>(0)
	const [isLinkDblCliked, setIsLinkDblCliked] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
		[],
	)
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
	const [nodeToDelete, setNodeToDelete] = useState<StructureDto | undefined>()
	const [folderNode, setFolderNode] = useState<StructureDto | undefined>()
	const [isAddNewNode, setIsAddNewNode] = useState(false)
	const [selectedNode, setSelectedNode] = useState()
	const [selectedLink, setSelectedLink] = useState<number | null>(null)
	const [selectedDiagram, setSelectedDiagram] = useState(true)
	const { data } = useApi(getNodeDetail(idNode))
	const { t } = useAppContext()
	const treeNodes = useAppStore((state: RootState) => state?.node?.nodes)

	const diagrams = useAppStore(
		(state) => state.folder?.folders[node?.id]?.form.diagrams,
	)
	const thisDiagram = diagrams?.find(
		(diagram) => diagram.id === selectedTabDetailId,
	)

	const routingType = thisDiagram?.properties?.links?.routingType

	const properties = thisDiagram?.properties

	const { handleDrop, handleDragOver, handleDragLeave } = useReactDiagramDrop({
		diagram,
		selectedTabDetailId,
		node,
	})

	const onModelChange = useDiagramChangeHandler(
		diagram.ref,
		node,
		selectedTabDetailId,
	)

	const { validateTablesInDiagram, handleUpdateDiagram } =
		useReactDiagramValidate({
			nodeId: node.id,
			diagram,
			isEditMode,
			onModelChange,
			setShowModal,
			onValidationErrors: (errors) => {
				setValidationErrors(errors)
				if (errors.length > 0) {
					setShowModal(true)
					markChanges(diagram.ref.current, validationErrors)
				}
			},
		})

	// BUGGY CODE IT SHARES THE REF WITH THE DIAGRAM INSTANCE
	// useImperativeHandle(ref, () => ({
	// 	validateTablesInDiagram,
	// }))

	const onAutoLayout = () => {
		diagram.makeAutoLayout()
	}

	const nodeCallback = (nodeId: number) => {
		setIdNode(nodeId)
		setIsLinkDblCliked(false)
	}

	const selectedNodeInfo = (nodeId: number) => {
		if (diagram.ref.current) {
			const diagramInstance = diagram.ref.current.getDiagram()
			const model = diagramInstance?.model as go.GraphLinksModel
			const nodeData = model.findNodeDataForKey(nodeId)
			setSelectedNode(nodeData)
			setSelectedLink(null)
			setSelectedDiagram(false)
		}
	}

	const selectedLinkInfo = (linkId: number) => {
		setSelectedLink(linkId)
		setSelectedNode(null)
		setSelectedDiagram(false)
	}

	const selectedDiagramInfo = () => {
		setSelectedNode(null)
		setSelectedLink(null)
		setSelectedDiagram(true)
	}

	const linkCallback = (nodeId: number) => {
		setIdNode(nodeId)
		setIsLinkDblCliked(true)
	}

	if (data) {
		useHandleData(data, isLinkDblCliked)
	}

	const handleClose = () => {
		const diagramRef = diagram.ref.current
		setShowModal(false)
		markChanges(diagramRef, validationErrors)
	}

	const allKeysFromNodes = useAppStore(
		(state) =>
			state?.folder?.folders[node.id]?.form.newDiagram?.allKeysFromNodes,
	)

	const gridVisible = thisDiagram?.properties?.grid?.isVisible || false
	const gridCellSize = thisDiagram?.properties?.grid?.cellSize || 10

	const useInitDiagram = () => {
		const $ = go.GraphObject.make
		const goJsDiagram = $(go.Diagram, {
			'undoManager.isEnabled': true,
			model: $(go.GraphLinksModel, {
				nodeKeyProperty: 'key',
				linkKeyProperty: 'key',
			}),
		})

		useContextMenuTemplate(
			goJsDiagram,
			addNewTable(
				treeNodes,
				(node) => setFolderNode(node as StructureDto),
				node.id,
				setIsAddNewNode,
			),
			selectedDiagramInfo,
			t,
		)

		goJsDiagram.nodeTemplate = $(
			go.Node,
			'Auto',
			new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(
				go.Point.stringify,
			),
		)

		goJsDiagram.nodeTemplateMap = setupNodeTemplates(
			allKeysFromNodes,
			treeNodes,
			nodeCallback,
			selectedNodeInfo,
			removeNode(diagram.ref),
			deleteNode(treeNodes, setShowDeleteModal, (node) =>
				setNodeToDelete(node as StructureDto),
			),
		)

		goJsDiagram.linkTemplateMap = setupLinkTemplates(
			selectedLinkInfo,
			linkCallback,
			routingType || LinkRoutingType.Orthogonal,
		)

		const horizontalLineColor =
			properties?.grid?.lineColors?.horizontal ||
			colors.grid.horizontalLineColor
		const verticalLineColor =
			properties?.grid?.lineColors?.vertical || colors.grid.verticalLineColor
		const intervalHorizontalLineColor =
			properties?.grid?.lineColors?.intervalHorizontal ||
			colors.grid.intervalHorizontalLineColor
		const intervalVerticalLineColor =
			properties?.grid?.lineColors?.intervalVertical ||
			colors.grid.intervalVerticalLineColor

		goJsDiagram.grid = new go.Panel('Grid', {
			gridCellSize: new go.Size(gridCellSize, gridCellSize),
		}).add(
			new go.Shape('LineH', {
				name: 'HorizontalLine',
				stroke: horizontalLineColor,
			}),
			new go.Shape('LineV', {
				name: 'VerticalLine',
				stroke: verticalLineColor,
			}),
			new go.Shape('LineH', {
				name: 'IntervalHorizontalLine',
				stroke: intervalHorizontalLineColor,
				interval: 5,
			}),
			new go.Shape('LineV', {
				name: 'IntervalVerticalLine',
				stroke: intervalVerticalLineColor,
				interval: 5,
			}),
		)

		return goJsDiagram
	}

	const nodeDataArray = thisDiagram?.nodeDataArray
	const { parsedLinks } = useParseNodesAndLinks(node?.id, selectedTabDetailId)

	useEffect(() => {
		if (diagram) {
			toggleGrid(diagram.ref, gridVisible)
		}
	}, [diagram, gridVisible])

	return (
		<Container>
			<DiagramControls
				onExportPng={diagram.exportPng}
				onExportSvg={diagram.exportSvg}
				onAutoLayout={onAutoLayout}
			/>
			<DiagramContainer
				onDrop={handleDrop}
				onDragEnter={handleDragOver}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDragEnd={handleDragLeave}
			>
				{/*{nodeDataArray && (*/}
				{/*	<ReactPalette*/}
				{/*		divClassName="palette"*/}
				{/*		initPalette={initPalette}*/}
				{/*		nodeDataArray={nodeDataArray}*/}
				{/*	/>*/}
				{/*)}*/}

				<ReactDiagram
					ref={diagram.ref}
					divClassName="diagram"
					initDiagram={useInitDiagram}
					nodeDataArray={nodeDataArray}
					linkDataArray={parsedLinks}
					onModelChange={onModelChange}
					onDiagramEvent={(event) => console.log('event changed', event)}
				/>

				<DiagramModals
					showModal={showModal}
					validationErrors={validationErrors}
					handleUpdateDiagram={handleUpdateDiagram}
					handleClose={handleClose}
					showDeleteModal={showDeleteModal}
					nodeToDelete={nodeToDelete}
					setShowDeleteModal={setShowDeleteModal}
					isAddNewNode={isAddNewNode}
					folderNode={folderNode}
					setIsAddNewNode={setIsAddNewNode}
				/>

				<DiagramPropertiesPanel
					selectedDiagram={selectedDiagram}
					selectedNode={selectedNode}
					selectedLink={selectedLink}
					diagramRef={diagram.ref}
					saveProperties={(properties) => onModelChange(undefined, properties)}
					nodeId={node.id}
					selectedTabDetailId={selectedTabDetailId}
				/>
			</DiagramContainer>
		</Container>
	)
}
