import go from 'gojs'

import { LinkRoutingType } from '@/enums'
import colors from '@/styles/diagramColors'

const arrows = {
	none: 'None',
	openTriangle: 'OpenTriangle',
	BackwardOpenTriangle: 'BackwardOpenTriangle',
}

// Type for common bindings
type BindingsType = Array<go.Binding | go.GraphObject>

// Type for link callback function
type LinkCallbackType = (node: number) => void

export const setupLinkTemplates = (
	setSelectedLink: (link: number) => void,
	linkCallback: LinkCallbackType,
	selectedLinksRoutingType: string,
) => {
	const $ = go.GraphObject.make
	const linkTemplates = new go.Map<string, go.Link>()
	const commonBindings: BindingsType = [
		$(go.Shape, new go.Binding('stroke', 'linksColor').makeTwoWay()),
		$(
			go.TextBlock,
			{ background: colors.nodes.background },
			new go.Binding('text'),
		),
	]

	const createArrowBinding: BindingsType = [
		$(
			go.Shape,
			new go.Binding('stroke', 'color').makeTwoWay(),
			new go.Binding('stroke', 'hasChanged', (hasChanged) =>
				hasChanged ? colors.nodes.hasChanged : 'black',
			),
			{ fromArrow: arrows.none },
			{ toArrow: 'Standard' },
		),
	]

	const defaultLinkTemplate = $(
		go.Link,
		{
			routing:
				selectedLinksRoutingType === LinkRoutingType.Orthogonal
					? go.Link.Orthogonal
					: go.Link.Normal,
			reshapable: true,
			resegmentable: true,
			relinkableFrom: true,
			relinkableTo: true,
			fromSpot: go.Spot.AllSides,
			toSpot: go.Spot.AllSides,
			fromEndSegmentLength: 20,
			toEndSegmentLength: 20,
			click: (event) => {
				const clickedLink = event.diagram.findPartAt(event.documentPoint)
				if (clickedLink && setSelectedLink) {
					setSelectedLink(clickedLink.data)
				}
			},
			doubleClick: (event) => {
				const clickedLink = event.diagram.findPartAt(event.documentPoint)
				if (clickedLink && linkCallback) {
					linkCallback(clickedLink.data.from)
				}
			},
		},
		...commonBindings,
		...createArrowBinding,
	)

	linkTemplates.set('', defaultLinkTemplate)
	return linkTemplates
}
